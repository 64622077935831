import XHRMock, { delay as xhrMockDelay, proxy } from 'xhr-mock';
import { MockConfig, HttpMock } from './types';

export * from './types';

/**
 * Orchestrator for setting up mocks.
 */
export const injectMocks = (mocks: HttpMock[], config?: MockConfig): void => {
  XHRMock.teardown();
  XHRMock.setup();

  if (!mocks || mocks.length === 0) {
    return;
  }

  mocks.forEach(handleRestMock);

  if (config?.allowXHRPassthrough) {
    XHRMock.use(proxy);
  }
};

function handleRestMock({ method, url, delay, response }: HttpMock) {
  if (delay) {
    XHRMock.use(method, url, xhrMockDelay(response, delay));
  } else {
    XHRMock.use(method, url, response);
  }
}
